import React from "react";

const Travels=()=>{
    return(
        <svg preserveAspectRatio="xMidYMid meet" data-bbox="31 51.001 138 97.999" viewBox="31 51.001 138 97.999" height="200" width="200" xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation" aria-hidden="true" aria-label="">
            <g>
                <path d="M154.625 108.647h-40.25v-5.765h40.25c4.801 0 8.625-4.139 8.625-5.765 0-3.493-12.115-8.647-23-8.647H59.749a2.866 2.866 0 0 1-1.842-.669L41.89 74.419l-3.776 1.261c2.827 4.892 8.504 14.495 12.429 19.699.657.873.766 2.044.281 3.022A2.873 2.873 0 0 1 48.249 100c-1.558 0-2.111.823-2.111 1.528 0 .86.769 1.354 2.111 1.354h40.25v5.765h-40.25c-2.923 0-4.736-1.153-5.744-2.118a6.864 6.864 0 0 1-2.117-5 7.23 7.23 0 0 1 3.215-5.976C38.21 87.628 31.685 76.035 31.37 75.474a2.89 2.89 0 0 1-.177-2.452 2.873 2.873 0 0 1 1.772-1.697l8.625-2.882a2.893 2.893 0 0 1 2.751.521l16.45 13.743h79.459c10.318 0 28.75 4.816 28.75 14.412 0 5.081-6.769 11.528-14.375 11.528z" fill="#ffffff" data-color="1"></path>
                <path d="M74.124 149c-.438 0-.879-.1-1.286-.304l-5.75-2.882a2.882 2.882 0 0 1-1.151-4.105l14.257-22.872 8.538-19.972 5.284 2.272-8.625 20.176a2.602 2.602 0 0 1-.205.391l-12.699 20.371 1.171.587 27.155-24.501 17.129-20.036 4.363 3.752-17.25 20.176a2.697 2.697 0 0 1-.258.266l-28.75 25.941a2.862 2.862 0 0 1-1.923.74z" fill="#ffffff" data-color="1"></path>
                <path d="M100.951 81.966L73.658 57.339l-1.171.587 12.699 20.371-4.874 3.054-14.375-23.059a2.886 2.886 0 0 1 1.151-4.105l5.75-2.882a2.872 2.872 0 0 1 3.209.436l28.75 25.941-3.846 4.284z" fill="#ffffff" data-color="1"></path>
            </g>
        </svg>
    )
}

export default Travels