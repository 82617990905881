import React from "react";
import CarouselPrincipal from "../components/Carousel";

function Slider(){
  return(
    <div id="slider">
      <CarouselPrincipal></CarouselPrincipal>
    </div>
  )
}

export default Slider