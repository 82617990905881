import React,{useState} from "react";

const Metting_planners=()=>{
    const [selected, setSelected] = useState(false);
    return(
        <svg preserveAspectRatio="xMidYMid meet" data-bbox="37.502 29.5 124.997 141.001" viewBox="37.502 29.5 124.997 141.001" height="200" width="200" xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation" aria-hidden="true" aria-label="">
                <g>
                    <path d="M48.945 110.606c-2.628 7.742 1.547 16.139 9.325 18.755 7.778 2.616 16.213-1.54 18.84-9.282l13.675-39.967h-31.38l-10.46 30.494z" fill="#ffffff" data-color="1"></path>
                    <path d="M149.321 112.087l-8.276-24.421-32.033 2.928 12.479 31.903c2.888 7.65 11.458 11.521 19.143 8.646 7.685-2.875 11.574-11.406 8.687-19.056z" fill="#ffffff" data-color="1"></path>
                    <path fill="#ffffff" d="M101.456 32.521a3.028 3.028 0 0 1-3.035 3.021 3.028 3.028 0 0 1-3.035-3.021 3.028 3.028 0 0 1 3.035-3.021 3.028 3.028 0 0 1 3.035 3.021z" data-color="2"></path>
                    <path fill="#ffffff" d="M111.176 45.063a3.028 3.028 0 0 1-3.035 3.021 3.028 3.028 0 0 1-3.035-3.021 3.028 3.028 0 0 1 3.035-3.021 3.028 3.028 0 0 1 3.035 3.021z" data-color="2"></path>
                    <path fill="#ffffff" d="M89.654 53.973a3.028 3.028 0 0 1-3.035 3.021 3.028 3.028 0 0 1-3.035-3.021 3.028 3.028 0 0 1 3.035-3.021 3.028 3.028 0 0 1 3.035 3.021z" data-color="2"></path>
                    <path d="M95.59 65.629l-28.166-9.472a2.516 2.516 0 0 0-1.915.128 2.502 2.502 0 0 0-1.264 1.439L46.228 110.81c-2.722 8.02 1.241 16.704 8.886 20.047L44.85 161.101l-4.038-1.358a2.51 2.51 0 0 0-3.179 1.566 2.495 2.495 0 0 0 1.574 3.165l12.829 4.315a2.51 2.51 0 0 0 3.179-1.566 2.495 2.495 0 0 0-1.574-3.165l-4.038-1.358 10.262-30.238c1.271.308 2.56.465 3.846.465 2.475 0 4.936-.568 7.219-1.693a16.152 16.152 0 0 0 8.217-9.354l18.017-53.087a2.487 2.487 0 0 0-.128-1.907 2.514 2.514 0 0 0-1.446-1.257zm-21.197 54.653a11.183 11.183 0 0 1-5.689 6.477 11.264 11.264 0 0 1-8.623.575l-1.007-.339h-.002l-1.008-.339c-5.899-1.984-9.076-8.375-7.084-14.247l17.214-50.722 23.413 7.874-17.214 50.721z" fill="#ffffff" data-color="2"></path>
                    <path d="M67.312 87.276a2.51 2.51 0 0 0 3.179-1.566l4.543-13.384a2.495 2.495 0 0 0-1.574-3.165 2.511 2.511 0 0 0-3.179 1.566l-4.543 13.384a2.496 2.496 0 0 0 1.574 3.165z" fill="#ffffff" data-color="2"></path>
                    <path d="M65.89 91.466a2.51 2.51 0 0 0-3.179 1.566l-1.336 3.936a2.495 2.495 0 0 0 1.574 3.165 2.51 2.51 0 0 0 3.179-1.566l1.336-3.936a2.495 2.495 0 0 0-1.574-3.165z" fill="#ffffff" data-color="2"></path>
                    <path d="M117.354 76.422a2.51 2.51 0 0 0-3.23-1.459 2.493 2.493 0 0 0-1.466 3.215l2.156 5.712a2.507 2.507 0 0 0 3.23 1.459 2.493 2.493 0 0 0 1.466-3.215l-2.156-5.712z" fill="#ffffff" data-color="2"></path>
                    <path d="M162.339 162.383a2.51 2.51 0 0 0-3.23-1.459l-3.99 1.492-11.279-29.88a16.143 16.143 0 0 0 7.788-7.917 16.06 16.06 0 0 0 .415-12.418l-19.8-52.453a2.51 2.51 0 0 0-3.23-1.459l-27.83 10.409a2.503 2.503 0 0 0-1.402 1.305 2.486 2.486 0 0 0-.064 1.91l19.8 52.453a16.16 16.16 0 0 0 8.528 9.074 16.317 16.317 0 0 0 6.731 1.459c1.466 0 2.934-.201 4.37-.601l11.277 29.875-3.991 1.493a2.493 2.493 0 0 0-1.466 3.215 2.507 2.507 0 0 0 3.23 1.459l6.338-2.37h.001l6.337-2.37a2.495 2.495 0 0 0 1.467-3.217zm-32.221-33.491a11.189 11.189 0 0 1-5.905-6.283l-18.917-50.116 23.134-8.653 18.917 50.116a11.12 11.12 0 0 1-.287 8.598 11.202 11.202 0 0 1-6.311 5.878l-1.993.745a11.264 11.264 0 0 1-8.638-.285z" fill="#ffffff" data-color="2"></path>
                </g>
        </svg>
    )
}

export default Metting_planners