import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import quienes_somos from '../../assets/videos/slider_quienes_somos.mp4';
import que_ofrecemos from '../../assets/videos/que_ofrecemos.mp4';
function CarouselConocenos() {
    return(
        <Carousel>
            <Carousel.Item>
            <div className='video-container'>
                <video src={quienes_somos} autoPlay muted loop className='video_content'>
                </video>
                <div className='carousel-caption'>
                <div className='text'>
                    <h1>¿quienes somos?</h1>
                    <p>Una empresa líder en la creación y ejecución de eventos corporativos innovadores desde el punto de diseño hasta la coordinación.</p>
                </div>
                </div>
            </div>
            </Carousel.Item>
            <Carousel.Item>
            <div className='video-container'>
                <video src={que_ofrecemos} autoPlay muted loop className='video_content'>
                </video>
                <div className='carousel-caption'>
                <div className='text'>
                    <h1>¿que ofrecemos?</h1>
                    <p>Nos enorgullece ofrecer una amplia gama de servicios  diseñados para llevar tus eventos corporativos al siguiente nivel. Nuestro objetivo es brindar experiencias únicas y memorables que impulsen el éxito de su empresa.</p>
                </div>
                </div>
            </div>
            </Carousel.Item>
        </Carousel>
    )
}

export default CarouselConocenos