import React,{useState} from "react";

const Teams=()=>{
    const [selected, setSelected] = useState(false);
    return(
        <svg preserveAspectRatio="xMidYMid meet" data-bbox="34.5 28 130.999 144" viewBox="34.5 28 130.999 144" height="200" width="200" xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation" aria-hidden="true" aria-label="">
            <g>
                <path d="M129.773 85.01h-5.955v-3.666c0-6.686-15.453-11.379-23.818-11.379-8.366 0-23.818 4.693-23.818 11.379v3.666h-5.955v-3.666c0-12.378 21.011-17.378 29.773-17.378 8.763 0 29.773 5 29.773 17.378v3.666z" fill="#ffffff" data-color="1"></path>
                <path d="M93.489 172H80.293a2.98 2.98 0 0 1-2.836-2.087l-8.853-27.909H51.749c-9.51 0-17.249-7.796-17.249-17.378V88.597c0-6.94 5.604-12.586 12.492-12.586h.449c6.585 0 12.935 2.783 17.423 7.635l9.589 10.363h16.615c.834 0 1.63.353 2.194.973.564.618.845 1.449.772 2.285-.436 5.099-4.108 14.741-14.875 14.741H63.643a2.97 2.97 0 0 1-2.128-.901l-.22-.227v7.127h11.863c6.303 0 11.944 4.045 14.034 10.065.036.104.067.211.092.318l9.106 39.939a3.011 3.011 0 0 1-.573 2.541 2.965 2.965 0 0 1-2.328 1.13zm-11.021-5.999h7.282l-8.23-36.094c-1.295-3.536-4.636-5.901-8.362-5.901h-14.84c-1.644 0-2.977-1.343-2.977-3v-16.255l-8.382-8.627 4.257-4.198 13.68 14.081H79.16c4.947 0 7.176-3.315 8.17-5.999H73.158a2.966 2.966 0 0 1-2.178-.955L60.509 87.736a17.864 17.864 0 0 0-13.068-5.725h-.449c-3.605 0-6.537 2.954-6.537 6.587v36.028c0 6.275 5.066 11.379 11.294 11.379h19.03a2.98 2.98 0 0 1 2.836 2.087l8.853 27.909z" fill="#ffffff" data-color="1"></path>
                <path d="M55.341 73.011c-8.07 0-14.886-6.868-14.886-14.998v-3c0-8.139 6.817-15.014 14.886-15.014s14.886 6.875 14.886 15.014v3c0 8.269-6.678 14.998-14.886 14.998zm0-27.013c-4.841 0-8.932 4.129-8.932 9.015v3c0 4.877 4.091 8.999 8.932 8.999s8.932-4.122 8.932-8.999v-3c0-4.886-4.091-9.015-8.932-9.015z" fill="#ffffff" data-color="1"></path>
                <path d="M119.149 172h-13.194a2.966 2.966 0 0 1-2.329-1.131 3.022 3.022 0 0 1-.573-2.541l9.106-39.939c.023-.107.055-.214.09-.318 2.093-6.02 7.734-10.065 14.037-10.065h12.418v-7.685l-.797.804a2.963 2.963 0 0 1-2.108.882H120.84c-10.766 0-14.439-9.642-14.875-14.741a3.005 3.005 0 0 1 .77-2.285 2.968 2.968 0 0 1 2.195-.973h16.061l9.586-10.363c4.489-4.852 10.839-7.635 17.422-7.635h.451c7.074 0 13.049 5.764 13.049 12.586v36.028c0 9.419-8.153 17.378-17.805 17.378H130.84l-8.856 27.911a2.979 2.979 0 0 1-2.835 2.087zm-9.455-5.999h7.28l8.856-27.911a2.98 2.98 0 0 1 2.835-2.086h19.03c6.423 0 11.851-5.211 11.851-11.379V88.597c0-3.509-3.315-6.587-7.094-6.587h-.451a17.857 17.857 0 0 0-13.066 5.727l-10.47 11.316a2.968 2.968 0 0 1-2.178.955h-13.616c.991 2.682 3.222 5.999 8.17 5.999h13.723l13.962-14.1 4.216 4.236-8.083 8.164v16.699c0 1.657-1.332 3-2.977 3h-15.395c-3.727 0-7.068 2.365-8.365 5.901l-8.228 36.094z" fill="#ffffff" data-color="1"></path>
                <path d="M144.659 73.011c-8.068 0-14.886-6.868-14.886-14.998v-3c0-8.139 6.818-15.014 14.886-15.014s14.886 6.875 14.886 15.014v3c0 8.13-6.818 14.998-14.886 14.998zm0-27.013c-4.841 0-8.932 4.129-8.932 9.015v3c0 4.877 4.091 8.999 8.932 8.999s8.932-4.122 8.932-8.999v-3c0-4.886-4.091-9.015-8.932-9.015z" fill="#ffffff" data-color="1"></path>
                <path fill="#ffffff" d="M141.682 106.007v5.999H58.318v-5.999h83.364z" data-color="1"></path>
                <path d="M100 61.012c-8.07 0-14.886-6.868-14.886-14.998v-3C85.114 34.875 91.93 28 100 28c8.068 0 14.886 6.875 14.886 15.014v3c0 8.131-6.818 14.998-14.886 14.998zm0-27.013c-4.841 0-8.932 4.129-8.932 9.015v3c0 4.877 4.091 8.999 8.932 8.999s8.932-4.122 8.932-8.999v-3c0-4.886-4.091-9.015-8.932-9.015z" fill="#ffffff" data-color="1"></path>
            </g>
        </svg>
    )
}

export default Teams